export const DASHBOARD_LOGS_REFRESH_TIMEOUT: number = 500;
export const DASHBOARD_TOAST_TTL: number = 3000;
export const DASHBOARD_TOAST_FADE_TIMEOUT: number = 500;
export const MODAL_AUTO_CLOSE_TIMEOUT =  150;
export const UNSAVED_CHANGES_WARNING_TEXT: string = "Unsaved changes will be lost. Are you sure you want to continue?";
export const DEFAULT_FORM_FIELD_BE_ERROR: string = "There seems to be an issue with this value. Please ensure it meets the required format.";
export const LISTING_MANAGEMENT_TOAST_TTL = 2000;
export const SERVER_ERROR_MESSAGE = "We encountered a server issue while processing your request. Please try again later.";
export const LISTING_NOT_FOUND_MESSAGE = "Unable to find the specified Listing.";
export const LINK_EXPIRED_MESSAGE = "Link is expired";
export const LISTING_ROOM_DEFAULT_NAME = "Room name";
