import React, { useEffect, useMemo, useRef, useState } from "react";
import { Typography } from "@mui/material";
import { BaseButton, BaseIcon, BlaceV2QueryConstructorHelper, Log } from "blace-frontend-library";
import classNames from "classnames";
import { useSearchParams } from "react-router-dom";
import { PaginationComponent } from "@/src/component/partial/Pagination";
import { useToast } from "@/src/component/provider";
import { AddListingModal } from "@/src/component/view/Listings/component/AddListingModal";
import { ListingsContainer } from "@/src/component/view/Listings/component/ListingsContainer";
import { LISTING_MANAGEMENT_TOAST_TTL, SERVER_ERROR_MESSAGE, STORAGE_LISTINGS_LAST_PAGE, URL_PAGINATION_PAGE_PARAM } from "@/src/const";
import { B2BSearchServiceV2 } from "@/src/service";
import { BlaceV2Type } from "@/src/type";
import styles from "./Listings.module.scss";

function Listings() {
  const [listings, setListings] = useState<BlaceV2Type.SearchType.SearchItem[]>([]);
  const [isListingsLoading, setIsListingsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isAddListingModalOpen, setIsAddListingModalOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [totalListingsCount, setTotalListingsCount] = useState(0);
  const latestListingsRequest = useRef<number>(0);
  const [searchParams] = useSearchParams();
  const { setToastMessage } = useToast();

  const LISTINGS_PER_PAGE = 20;
  const ORDER_BY_DESC = false;

  const totalPages = Math.ceil(totalListingsCount / LISTINGS_PER_PAGE);

  const listingsContainerClasses = classNames(styles.listingsWrapper, {
    [styles.isLoading]: isListingsLoading,
  });

  const getPaginationFrom = (page: number, size: number) => {
    return page * size - size;
  };

  const orderBy = useMemo(() => {
    return ["substring(title, 1, 64)"];
  }, []);

  const openAddListingModal = () => {
    setIsAddListingModalOpen(true);
  };

  const closeAddListingModal = () => {
    setIsAddListingModalOpen(false);
  };

  const handleCloseAddListingModal = () => {
    closeAddListingModal();
  };

  useEffect(() => {
    const pageFromUrl = Number(searchParams.get(URL_PAGINATION_PAGE_PARAM));
    if (pageFromUrl > 0 && page !== pageFromUrl) {
      setPage(pageFromUrl);
    }
    if (page && page > 0) {
      localStorage?.setItem(STORAGE_LISTINGS_LAST_PAGE, page.toString());
    } else {
      localStorage?.removeItem(STORAGE_LISTINGS_LAST_PAGE);
    }
  }, [searchParams, page]);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    let isMounted = true;
    const currentRequest = ++latestListingsRequest.current;

    const fetchData = async () => {
      try {
        setIsListingsLoading(true);
        setIsError(false);
        const queryConstructor = new BlaceV2QueryConstructorHelper.BlaceV2QueryConstructor(
          LISTINGS_PER_PAGE,
          getPaginationFrom(page, LISTINGS_PER_PAGE),
        );
        // we need to ignore vendor duplicates, which were imported as venues
        queryConstructor.setValue("status", BlaceV2Type.ListingStatus.VENDOR_DUPLICATE, "not");
        queryConstructor.setOrderBy(orderBy);
        queryConstructor.setOrderDesc(ORDER_BY_DESC);

        const listingsResponse = await B2BSearchServiceV2.getAllListings(
          queryConstructor ? queryConstructor.getQuery() : null,
          signal,
        );

        if (!isMounted || currentRequest !== latestListingsRequest.current) {
          Log.logToConsoleDebug(
            "Listings.tsx",
            "fetchListingsOutdated",
            ["The Listings request was outdated."],
          );
          return;
        }

        if (listingsResponse.body?.metadata?.statusCode === 200) {
          setListings(listingsResponse.body?.payload.results);
          setTotalListingsCount(listingsResponse.body?.payload.count);
        } else {
          // prettier-ignore
          Log.logToDataDog(
            Log.LogLevel.ERROR,
            "Listings.tsx",
            "fetchListingsError",
            [listingsResponse]
          );
          setIsListingsLoading(false);
          setIsError(true);
          setToastMessage(SERVER_ERROR_MESSAGE, "error", LISTING_MANAGEMENT_TOAST_TTL);
        }
        setIsListingsLoading(false);
      } catch (err) {
        const error = err as Error;
        if (!isMounted || currentRequest !== latestListingsRequest.current) {
          Log.logToConsoleDebug(
              "Listings.tsx",
              "fetchListingsOutdated",
              ["The Listings request was outdated."],
          );
        } else if (error.name === "AbortError") {
          Log.logToConsoleDebug(
            "Listings.tsx",
            "fetchListingsAbort",
            [error],
          );
        } else {
          // prettier-ignore
          Log.logToDataDog(
              Log.LogLevel.ERROR,
              "Listings.tsx",
              "fetchListingsError",
              [error],
          );
          setIsListingsLoading(false);
          setIsError(true);
          setToastMessage(SERVER_ERROR_MESSAGE, "error", LISTING_MANAGEMENT_TOAST_TTL);
        }
      }
    };
    fetchData();

    // Cleanup function to abort previous requests
    return () => {
      controller.abort();
      isMounted = false;
    };
  }, [page, orderBy, ORDER_BY_DESC, setToastMessage]);

  return (
    <>
      <div className={styles.listingsContainer} data-testid="listingsPage">
        <div className={styles.pageHeaderContainer}>
          <div className={styles.pageTitleContainer}>
            <Typography className={styles.pageTitle}>Manage your listings</Typography>
            <Typography className={styles.pageSubtitle}>
              Here you can view, update, and create new listings
            </Typography>
          </div>
          <BaseButton
            onClick={openAddListingModal}
            startIcon={<BaseIcon iconFileName="layers" iconAlt="layers icon" iconSize={24} />}
            className={styles.addListingButton}
          >
            Add a New Listing
          </BaseButton>
        </div>
        <div className={listingsContainerClasses}>
          <ListingsContainer listings={listings} isLoading={isListingsLoading} isError={isError} />
        </div>
        {totalListingsCount > LISTINGS_PER_PAGE && !!listings.length && (
          <div className={styles.paginationWrapper} data-testid="pagination">
            <PaginationComponent
              paginationPagesAmount={totalPages}
              paginationCurrentPage={page}
              withSearchId={false}
              withTopBorder={false}
            />
          </div>
        )}
      </div>
      <AddListingModal isOpen={isAddListingModalOpen} onClose={handleCloseAddListingModal} />
    </>
  );
}

export default Listings;
