import { ChangeEvent } from "react";
import { Pagination } from "@mui/material";
import { useBreakPointBetween } from "blace-frontend-library";
import cn from "classnames";
import { useSearchParams } from "react-router-dom";
import { URL_PAGINATION_PAGE_PARAM } from "@/src/const";
import { uniqueId } from "@/src/util";
import styles from "./Pagination.module.scss";

interface PaginationProps {
  paginationPagesAmount: number;
  paginationCurrentPage: number;
  withSearchId?: boolean;
  withTopBorder?: boolean;
  responsiveSize?: boolean;
}

function PaginationComponent({
  paginationPagesAmount,
  paginationCurrentPage,
  withSearchId = true,
  withTopBorder = true,
  responsiveSize = false
}: PaginationProps) {
  const [searchParams, setSearchParams] = useSearchParams();
  const isMediumWidth = useBreakPointBetween(["md", "lg"]);

  const handlePaginationChange = (
    _event: ChangeEvent<unknown>,
    value: number
  ) => {
    searchParams.set(URL_PAGINATION_PAGE_PARAM,`${value}`);
    withSearchId && searchParams.set("searchId",uniqueId());
    setSearchParams(searchParams);

    window.scrollTo(0,0);
  };

  return (
    <div className={cn(styles.paginationWrapper, {[styles.withTopBorder]: withTopBorder})} data-testid="paginationWrapper">
      <Pagination
        count={paginationPagesAmount}
        page={paginationCurrentPage}
        color="standard"
        shape="rounded"
        size={isMediumWidth && responsiveSize ? "small" : "medium"}
        siblingCount={1}
        boundaryCount={0}
        onChange={handlePaginationChange}
      />
    </div>
  );
}

export default PaginationComponent;
